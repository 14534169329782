import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import dayjs from 'dayjs'
import { SharedService } from '@/App/Services/Shared.service'
import DatePicker from '@/App/Components/Pickers/DatePicker/Date-Picker.vue'
import OrganizationSelect from '@/App/Components/OrganizationSelect/Organization-Select.vue'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { SMSLog } from '@/App/Services/interfaces/shared.interface'
import { UserInfo } from '@/App/Services/interfaces/user.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { DatePicker, OrganizationSelect }
})
export default class SMSLogsPage extends Vue {
  @sharedStore.State
  private readonly userInfo: UserInfo

  public isLoading = false
  public search = ''
  public logsItems: SMSLog[] = []
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('smsLogsPage.phoneText').toString(), align: 'left', sortable: true, value: 'phone' },
    { text: this.$t('smsLogsPage.textText').toString(), align: 'left', sortable: true, value: 'text' },
    { text: this.$t('smsLogsPage.otherText').toString(), align: 'left', sortable: true, value: 'other' },
    { text: this.$t('smsLogsPage.dateText').toString(), align: 'left', sortable: true, value: 'dtCreate' },
    { text: this.$t('smsLogsPage.statusText').toString(), align: 'left', sortable: true, value: 'success' }
  ]
  public from = dayjs().format('YYYY-DD-MM')
  public to = dayjs().format('YYYY-DD-MM')
  public organizationId = ''

  public async getLogs(): Promise<void> {
    try {
      this.isLoading = true

      const from = dayjs(this.from).startOf('day').unix() * 1000
      const to = dayjs(this.to).endOf('day').unix() * 1000
      this.logsItems = await SharedService.fetchSMSLogs(from, to, this.organizationId)
    } catch {} finally {
      this.isLoading = false
    }
  }

  get isOrganizationSelectShow(): boolean {
    return this.userInfo.role === 'root'
  }
}
